import $ from "jquery";

function determineNextLink($links) {
    const $currentLink = $links.find(".active");
    let $nextLink = $currentLink.next();
    if($nextLink.length == 0) {
      $nextLink = $links.find("li:first");
    }
    return $nextLink;
}

$(function() {
  const $rdnavi = $(".referenzdetailnavi");
  if($rdnavi.length == 0) {
    return;
  }

  const $links = $rdnavi.find(".referenzlinks");
  const $nextLinkTpl = determineNextLink($links);

  const nextUrl = $nextLinkTpl.find("a").attr("href");

  const $nextlink = $rdnavi.find(".nextlink");
  $nextlink.attr("href", nextUrl);
});
  