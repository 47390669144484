import $ from "jquery";
import "jquery.scrollto";

$(function() {
    const $uparrow = $("footer .uparrow");
    $uparrow.on("click", function() {
        /*
        //Reine JavaScript Loesung: Speed kann nicht angegeben werden.
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
        */

        //jQuery.scrollTo
        $(window).scrollTo(0, 1500);
    });
});
