import $ from "jquery";

$(function() {
  const $philosophieblock = $(".philosophieblock");
  if($philosophieblock.length == 0) {
    return;
  }

  $philosophieblock.find(".grundatz").each(function() {
    const $grundatz = $(this);
    const ismobile = window.innerWidth <= 640;
    const factor = (ismobile ? 0.5 : 1);
    const $bg = $grundatz.find(".bg");
    const $image01 = $grundatz.find(".iconimg1");
    const $image02 = $grundatz.find(".iconimg2");
    const $image03 = $grundatz.find(".iconimg3");

    function updateTransform() {
      const bgrect = $bg.get(0).getBoundingClientRect();
      const pct = (bgrect.top/*-window.innerHeight/4*/)/window.innerHeight;
      window.requestAnimationFrame(function() {
        $image01.css("transform", "translateY("+pct*factor*-200+"px)");
        $image02.css("transform", "translateY("+pct*factor*-100+"px)");
        //$image03.css("transform", "translateY("+scrollY*factor*0+"px)");
      });
    }

    $(window).on("scroll", updateTransform);
    $(window).on("load", updateTransform);
    updateTransform();
  });
});
