import $ from "jquery";

const $body = $("body");

/*
function isElementInViewportPartial(el) {
	var rect = el.getBoundingClientRect();
	var innerh = window.innerHeight || document.documentElement.clientHeight;
    const enteredViewport = rect.top <= innerh*0.4; //Higher number: Show earlier
    const leftViewport = rect.bottom < innerh*0.4; //Lower number: Show longer
	return enteredViewport && !leftViewport;
}
*/

$(function() {

    function getSectionAt(xpos, ypos) {
        const elements = document.elementsFromPoint(xpos, ypos);
        for(const element of elements) {
            if(element.tagName === "SECTION") {
                return element;
            }
        }
        return null;
    }

    function updateBackgroundColor() {
        let bgcolor = undefined;
        let brightfont = false;

        const currentSection = getSectionAt(window.innerWidth*0.5, window.innerHeight*0.5);
        if(currentSection) {
            const bgcolorswitch = $(currentSection).data("bgcolorswitch");
            if(bgcolorswitch) {
                if(bgcolorswitch.startsWith("b")) {
                    //Bright color => Dark font
                    bgcolor = bgcolorswitch.substring(1);
                    brightfont = false;
                } else {
                    bgcolor = bgcolorswitch;
                    brightfont = true;
                }
            }
        }
        if(bgcolor) {
            $body.css("background-color", bgcolor);
        } else {
            $body.css("background-color", "");
        }
        $body.toggleClass("brightfont", brightfont);
    }

    function updateLogoVisibility() {
        const currentSection = getSectionAt(window.innerWidth*0.5, window.innerHeight*0.1);

        const showlogo = (currentSection ? $(currentSection).data("showlogo")=="1" : false);
        if(showlogo) {
            $body.addClass("logovisible");
        } else {
            $body.removeClass("logovisible");
        }
    }
    
    window.setInterval(updateBackgroundColor, 150);
    window.setInterval(updateLogoVisibility, 150);
});

$(function() {
    function updateYScrollClasses() {
		if(window.scrollY < 10) {
			$body.addClass("scrollyeq0");
			$body.removeClass("scrollygt0");
		} else {
			$body.addClass("scrollygt0");
			$body.removeClass("scrollyeq0");
		}
	}
	window.setInterval(updateYScrollClasses, 150);
    updateYScrollClasses();    
});
