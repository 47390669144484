import $ from "jquery";
import ScrollReveal from "scrollreveal";

function pickRandom(arr)
{
  const randomIdx = Math.floor(Math.random() * arr.length);
  return arr[randomIdx];
}

function insertRandomItem($parallaximage)
{
  const $allItems = $parallaximage.find(".randomitem");
  if($allItems.length == 0) {
    return;
  }
  const $randomItem = pickRandom($allItems);
  $parallaximage.get(0).appendChild($randomItem.content);

  fadein($parallaximage);
}

function fadein($parallaximage) {
  $parallaximage.css("opacity", 0.01); //Google Chrome animiert nicht, wenn 0
  $parallaximage.delay(500).animate({"opacity": 1}, 700);
  /*ScrollReveal().reveal($parallaximage, {
    origin: "bottom",
    distance: "60px",
    easing: "cubic-bezier(.6,.26,.35,.74)",
    duration: 700,
    interval: 100,
  });*/
}

$(function() {
  const $parallaximages = $(".parallaximage");
  if($parallaximages.length == 0) {
    return;
  }

  $parallaximages.each(function() {
    const $parallaximage = $(this);
    insertRandomItem($parallaximage);
  });
});
  