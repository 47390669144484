import $ from "jquery";

$(function () {
    const $filterbuttons = $(".referenzfilterbuttons");
    if ($filterbuttons.length == 0) {
        return;
    }

    const $referenzoverview = $(".referenzoverview");

    function showItem($item, doshow) {
        $item.toggle(doshow);
        $item.toggleClass("visible", doshow);
    }

    function updateFilterbuttonState(filterLeistung) {
        $filterbuttons.find(".filterbutton").removeClass("active");
        $filterbuttons.find(".filterbutton[data-leistung='"+filterLeistung+"']").addClass("active");
    }

    function applyFilter(filterLeistung) {
        $referenzoverview.find(".item").each(function () {
            const $item = $(this);
            if (filterLeistung == "*") {
                showItem($item, true);
            } else {
                const itemLeistungen = $item.data("refleistungen");
                showItem($item, itemLeistungen.includes(filterLeistung));
            }
        });
        setEvenOddClasses();
    }

    function setEvenOddClasses() {
        $referenzoverview.find(".item").removeClass(["evenodd0", "evenodd1"]);
        let idx = 1;
        $referenzoverview.find(".item.visible").each(function () {
            const $item = $(this);
            $item.addClass("evenodd"+(idx%2));
            idx++;
        });
    }

    function applyFilterAnimated(filterLeistung) {
        $referenzoverview.fadeOut(400, function () {
            applyFilter(filterLeistung);
            $referenzoverview.fadeIn(400);
        });
    }

    $filterbuttons.on("click", ".filterbutton", function () {
        const $button = $(this);
        const filterLeistung = $button.data("leistung");
        updateFilterbuttonState(filterLeistung);
        applyFilterAnimated(filterLeistung);
    });

    if(window.location.hash) {
        const hashFilter = window.location.hash.substring(1); //z.B. "Planung"
        updateFilterbuttonState(hashFilter);
        applyFilter(hashFilter);
    }
});
