import { ready } from "./utils.js";

ready(() => {
  const hamburger = document.getElementById("hamburger");

  if (hamburger) {
    hamburger.addEventListener("click", () => {
      hamburger.classList.toggle("is-active");

      window.setTimeout(updateMenuVisible, 100);
    });
  }
});

function updateMenuVisible()
{
  const $body = $("body");
  const $offcanvas = $("#menu-offcanvas");
  const isopen = $offcanvas.hasClass("is-open");
  $body.toggleClass("menuopen", isopen);

  const $logo = $("header .logo");
  const newsrc = (isopen ? "/assets/app/images/logowhite.svg" : "/assets/app/images/logo.svg");
  $logo.attr("src", newsrc);
}