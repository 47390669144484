export function addFunctionalityToProgressPath(progressWrapClass, offset) {
  if (!progressPath) return;

  if (isNaN(offset)) {
    offset = 50;
  }

  const progressWrap = getProgressWrap(progressWrapClass);
  const progressPath = getProgressPath(classOfProgressCircle);
  const pathLength = progressPath.getTotalLength();

  addStyles(progressPath, pathLength);

  updateProgress(progressPath, pathLength);

  listenGoTopOnClick();

  document.addEventListener("scroll", function () {
    updateProgress(progressPath, pathLength);

    // element is just visible if it has active class
    // remove class if progress circle is above offset
    toggleActiveProgressClass(progressWrapClass, offset);
  });
}

function getProgressWrap() {
  return document.getElementsByClassName(".progress-wrap");
}

function getProgressPath() {
  return document.querySelector(".progress-wrap path");
}

function addStyles(progressPath, pathLength) {
  progressPath.style.transition = progressPath.style.WebkitTransition = "none";
  progressPath.style.strokeDasharray = pathLength + " " + pathLength;
  progressPath.style.strokeDashoffset = pathLength;
  progressPath.getBoundingClientRect();
  progressPath.style.transition = progressPath.style.WebkitTransition =
    "stroke-dashoffset 10ms linear";
}

function listenGoTopOnClick(progressWrap) {
  progressWrap.addEventListener("click", function (event) {
    event.preventDefault();
    window.scrollTo({ top: 0, behavior: "smooth" });
    return false;
  });
}

function updateProgress(progressPath, pathLength) {
  const bodyHeight = document.body.offsetBody;
  const windowHeight = window.innerHeight;
  const pixelsToTop = window.scrollTop;
  const height = bodyHeight - windowHeight;

  const progress = pathLength - (pixelsToTop * pathLength) / height;
  progressPath.style.strokeDashoffset = progress;
}

const ACTIVE_PROGRESS = "active-progress";
function toggleActiveProgressClass(progressWrapClass, offset) {
  const classes = document.querySelector(progressWrapClass).classList;
  if (document.scrollingElement.scrollTop > offset) {
    classes.add(ACTIVE_PROGRESS);
  } else {
    classes.remove(ACTIVE_PROGRESS);
  }
}
