import $ from "jquery";
import "what-input";
import libs from "./lib/dependencies.js";

window.$ = $;
window.jQuery = $;
window.libs = libs;

//import Foundation from 'foundation-sites';
// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
//import 'foundation-sites'
import "./lib/foundation-explicit-pieces.js";

//Pixmill Scripts
import { ready } from "./px/utils.js";

import "./px/hamburger.js";
import "./px/opaque.js";
import "./px/form.js";
import "./px/lazyload.js";
import { addFunctionalityToProgressPath } from "./px/scrollProgress.js";

ready(function () {
  addFunctionalityToProgressPath("progress-wrap");
});

$(document).foundation();


import "./px/homeposter.js";
import "./px/scrollcolor.js";
import "./px/topbutton.js";
import "./px/referenzDetailNavi.js";
import "./px/referenzFilterbuttons.js";
import "./px/parallaximage.js";
import "./px/scrollreveal.js";
import "./px/philosophieblock.js";
