import $ from "jquery";

function pickRandom(arr)
{
  const randomIdx = Math.floor(Math.random() * arr.length);
  return arr[randomIdx];
}

function insertRandomItem($homeposter)
{
  const $allItems = $homeposter.find(".randomitem");
  if($allItems.length == 0) {
    return;
  }
  const $randomItem = pickRandom($allItems);
  $homeposter.get(0).appendChild($randomItem.content);
}

$(function() {
  const $homeposters = $(".homeposter");
  if($homeposters.length == 0) {
    return;
  }

  $homeposters.each(function() {
    const $homeposter = $(this);
    
    insertRandomItem($homeposter);

    const $claim = $homeposter.find(".claim");
    const $image01 = $homeposter.find(".image01");
    const $image02 = $homeposter.find(".image02");
    const $image03 = $homeposter.find(".image03");
    $(window).on("scroll", function() {
      const scrollY = window.scrollY;
      window.requestAnimationFrame(function() {
        //$claim.css("transform", "translateY("+scrollY*-0.75+"px)");

        //Version mit Skalierung
        /*
        $image01.css("transform", "translateY("+scrollY*-0.15+"px) scale("+(1+scrollY*0.0001)+")");
        $image02.css("transform", "translateY("+scrollY*-0.25+"px) scale("+(1+scrollY*0.0004)+")");
        $image03.css("transform", "translateY("+scrollY*-0.5+"px) scale("+(1+scrollY*0.0006)+")");
        */

        //Version ohne Skalierung
        const ismobile = window.innerWidth <= 640;
        const factor = (ismobile ? 0.5 : 1);
        $image01.css("transform", "translateY("+scrollY*factor*-0.15+"px)");
        $image02.css("transform", "translateY("+scrollY*factor*-0.25+"px)");
        $image03.css("transform", "translateY("+scrollY*factor*-0.5+"px)");
      });
    });
  });
});
  