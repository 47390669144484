import $ from "jquery";
import ScrollReveal from "scrollreveal";

//scrollreveal
$(function() {
  ScrollReveal().reveal(".fade-in-bottom", {
    origin: "bottom",
    distance: "60px",
    easing: "cubic-bezier(.6,.26,.35,.74)",
    duration: 700,
    interval: 100,
  });
});